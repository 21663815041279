export enum QuoteAcceptStatus {
    ACCEPTED = 1,
    REFERRED = 2,
    DECLINED = 3
}
export interface QuoteAcceptData {
    purchase_order: string
    comments: string
    status: QuoteAcceptStatus
    signature: string
}