import { FC, useState, useRef } from 'react'
import SignaturePad from 'react-signature-pad-wrapper'

interface SignatoryPopupProps {
	isOpen: boolean
	onConfirm: (signature: string) => void
	onClose: () => void
	acceptButtonText?: string
}

export const SignatoryPopup: FC<SignatoryPopupProps> = ({
	isOpen,
	onConfirm,
	onClose,
	acceptButtonText = 'Accept',
}) => {
	const [error, setError] = useState<string | null>(null)
	const signaturePadRef = useRef<SignaturePad | null>(null)

	// Handle confirming the signature
	const handleConfirm = () => {
		const signaturePad = signaturePadRef.current

		if (signaturePad && !signaturePad.isEmpty()) {
			const signature = signaturePad.toDataURL() // Get the signature as a base64 string
			onConfirm(signature)
			handleClose() // Close the modal after confirming
		} else {
			setError('Please provide a signature.')
		}
	}

	// Handle clearing the signature
	const handleClear = () => {
		signaturePadRef.current?.clear()
		setError(null)
	}

	// Handle modal close
	const handleClose = () => {
		handleClear() // Clear the signature when closing
		onClose()
	}

	return (
		<>
			{isOpen && (
				<div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
					<div className="bg-white p-6 rounded-lg shadow-lg w-[600px]">
						{/* Title and Clear button */}
						<div className="flex justify-between items-center mb-4">
							<h2 className="text-lg font-semibold">Sign Here</h2>
							<button
								className="px-4 py-2 bg-gray-300 text-black rounded"
								onClick={handleClear}>
								Clear
							</button>
						</div>

						{/* Signature pad container */}
						<div className="border border-gray-300 rounded-md mb-4">
							<SignaturePad
								ref={signaturePadRef}
								options={{
									penColor: 'black',
									backgroundColor: 'rgba(255, 255, 255, 0)',
								}}
							/>
						</div>

						{/* Error message */}
						{error && <p className="text-red-500 text-sm">{error}</p>}

						{/* Action buttons */}
						<div className="flex justify-between mt-6">
							<button
								className="px-4 py-2 bg-gray-300 text-black rounded"
								onClick={handleClose}>
								Cancel
							</button>
							<button
								className="px-4 py-2 bg-blue-600 text-white rounded"
								onClick={handleConfirm}>
								{acceptButtonText}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
